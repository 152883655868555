.json-schema-2020-12-accordion {
  outline: none;
  border: none;
  padding-left: 0;

  &__children {
    display: inline-block;
  }

  &__icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: bottom;

    &--expanded {
      transition: transform 0.15s ease-in;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }

    &--collapsed {
      transition: transform 0.15s ease-in;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }

    & svg {
      height: 20px;
      width: 20px;
    }
  }
}
