.models
  .json-schema-2020-12:not(.json-schema-2020-12--embedded)
  > .json-schema-2020-12-head
  .json-schema-2020-12__title:first-of-type {
  font-size: 16px;
}

.models .json-schema-2020-12:not(.json-schema-2020-12--embedded) {
  width: calc(100% - 40px);
  overflow-x: auto;
}
