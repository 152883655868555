@use "sass:color";
@use "variables" as *;

// - - - - - - - - - - - - - - - - - - -
// - - _mixins.scss module
// styles for the _mixins.scss module
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

%clearfix {
  &:before,
  &:after {
    display: table;

    content: " ";
  }
  &:after {
    clear: both;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

$ease: (
  in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
  in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
  out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
  out-expo: cubic-bezier(0.19, 1, 0.22, 1),
  out-circ: cubic-bezier(0.075, 0.82, 0.165, 1),
  out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
  in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),
  in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
  in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
  in-out-expo: cubic-bezier(1, 0, 0, 1),
  in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86),
  in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
);

@function ease($key) {
  @if map-has-key($ease, $key) {
    @return map-get($ease, $key);
  }

  @warn 'Unkown \'#{$key}\' in $ease.';
  @return null;
}

@mixin ease($key) {
  transition-timing-function: ease($key);
}

@mixin text-truncate {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;

    width: 100%;
    padding-top: ($height / $width) * 100%;

    content: "";
  }
  > iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

@mixin maxHeight($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin breakpoint($class) {
  @if $class == tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $class == mobile {
    @media (min-width: 320px) and (max-width: 736px) {
      @content;
    }
  } @else if $class == desktop {
    @media (min-width: 1400px) {
      @content;
    }
  } @else {
    @warn 'Breakpoint mixin supports: tablet, mobile, desktop';
  }
}

@mixin invalidFormElement() {
  animation: shake 0.4s 1;
  border-color: $color-delete;
  background: color.adjust($color-delete, $lightness: 35%);
}

@mixin method($color) {
  border-color: $color;
  background: rgba($color, 0.1);

  .opblock-summary-method {
    background: $color;
  }

  .opblock-summary {
    border-color: $color;
  }

  .tab-header .tab-item.active h4 span:after {
    background: $color;
  }
}
