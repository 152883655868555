@use "./../../../../../style/variables" as *;
@use "./../../../../../style/type";

.json-schema-2020-12-expand-deep-button {
  @include type.text_headline($section-models-model-title-font-color);
  font-size: 12px;
  color: rgb(175, 174, 174);
  border: none;
  padding-right: 0;
}
